<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="relative">

    <div class="vx-navbar-wrapper" :class="classObj">

      <vs-navbar class="vx-navbar navbar-custom navbar-skelton" :color="navbarColorLocal" :class="textColor">

        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon class="sm:inline-flex xl:hidden cursor-pointer p-2" icon="MenuIcon" @click.stop="showSidebar" />

        <investor-credit />
        <vs-chip v-if="contractLevel==1" class="ml-2 mt-1 text-md bold cursor-pointer" color="primary" @click.native="goToIncrementLevel">Aumentar límite</vs-chip>
        <vs-chip v-else-if="contractLevel==2" class="ml-2 mt-1 text-md bold cursor-pointer" color="primary" @click.native="goToPayFunds">Abonar fondos</vs-chip>

        <!--investor-plan-info v-if="realShowPlanChip" /-->

        <!-- PILLS DE IMPACTAMÁS E IMPACTO DE INVERSIONISTA DESHABILITADAS 29/06/23 -->
        <!-- <div class="lg:ml-3 md:ml-2 ml-1 mt-1 cursor-pointer" @click="goToImpactaMasPage" v-if="step1Visible">
          <vs-chip
            class="text-md bold"
            color="dark"> ImpactaMás: {{roleGeneralData.level}}
          </vs-chip>
        </div> -->
        <!-- <investor-impacts-prefs v-if="step2Visible" /> -->
        <!-- PILLS DE IMPACTAMÁS E IMPACTO DE INVERSIONISTA DESHABILITADAS 29/06/23 -->

        <!--div class="lg:ml-3 md:ml-2 ml-1 mt-1" v-if="step2Visible" >
          <vs-chip
            class="text-md bold"
            transparent
            color="dark">
            <feather-icon icon="SunIcon" class="mr-2" svg-classes="h-4 w-4" />{{environmentalImpact}} Ton CO<sub>2</sub>
          </vs-chip>
        </div-->

        <!--div class="lg:ml-3 md:ml-2 ml-1 mt-1" v-if="step3Visible">
          <vs-chip
            class="text-md bold"
            color="dark">
            <feather-icon icon="ZapIcon" class="mr-2" svg-classes="h-4 w-4" />{{economicImpact}}
          </vs-chip>
        </div-->

        <!--div class="lg:ml-3 md:ml-2 ml-1 mt-1" v-if="step4Visible" >
          <vs-chip
            class="text-md bold"
            transparent
            color="dark">
            <feather-icon icon="GridIcon" class="mr-2" svg-classes="h-4 w-4" />{{panelsImpact}}
          </vs-chip>
        </div-->

        <vs-spacer />

        <div class="navbar-bookmarks flex items-center mr-5">
          <ul class="vx-navbar__starred-pages">
            <div class="flex cursor-move">
              <li class="starred-page v-step-13" v-for="page in starredPagesLimited" :key="page.id">
                <vx-tooltip :text="page.title" position="bottom" color="primary">
                  <feather-icon :svgClasses="['h-6 w-6 stroke-current', textColor]" class="p-2 cursor-pointer" :icon="page.icon" @click="execMethod(page.action)" />
                </vx-tooltip>
              </li>
            </div>
          </ul>
        </div>

        <!--notification-drop-down /-->

        <investor-profile-drop-down />

      </vs-navbar>
    </div>
  </div>
</template>


<script>
import NotificationDropDown from './components/NotificationDropDown.vue'
import InvestorProfileDropDown from './components/InvestorProfileDropDown.vue'
import InvestorCredit from '@components/investor/nav/InvestorCredit.vue'
import InvestorPlanInfo from "@components/investor/nav/InvestorPlanInfo";
import InvestorImpactsPrefs from "@components/investor/nav/InvestorImpactsPrefs";
import {mapState} from "vuex";
import formatHelper from "@mixins/formatHelper";

const navIconsDefinitions = [
  { id: 0, title: "Calendario", icon: "CalendarIcon", action: "showCalendar", condition: "supplier_status_4_or_more", },
  //{ id: 1, title: "Bitácora", icon: "MessageSquareIcon", action: "showGeneralLog" },
  //{ id: 2, title: "Dinos qué opinas", icon: "ThumbsUpIcon", action: "showFeedback" },
];

export default {
  name: 'investor-navbar-vertical',
  data (){
    return {
      showNotifs: false,
      stepPlan : 750,
      step1 : 650,
      step2 : 830,
      step3 : 1000,
      step4 : 1100,
      offsetPlan: 300,
    }
  },
  props: {
    navbarColor: {
      type: String,
      default: '#fff'
    }
  },
  mixins: [formatHelper],
  components: {
    NotificationDropDown,
    InvestorProfileDropDown,
    InvestorCredit,
    InvestorPlanInfo,
    InvestorImpactsPrefs,
  },
  computed: {
    ...mapState("auth", ["roleGeneralData"]),
    starredPagesLimited(){
      return navIconsDefinitions.filter(f => {
        if(f.condition != null){
          return (f.condition in this && this[f.condition] == true);
        }
        return true;
      })
    },
    navbarColorLocal () {
      if(this.$store.state.theme === 'rg-semi-dark'){
        return "";
      }
      else {
        return this.$store.state.theme === 'dark' && this.navbarColor === '#fff' ? '#10163a' : this.navbarColor
      }

    },
    verticalNavMenuWidth () {
      return this.$store.state.verticalNavMenuWidth
    },
    textColor () {
      return {'text-white': (this.navbarColor !== '#10163a' && this.$store.state.theme === 'dark') || (this.navbarColor !== '#fff' && this.$store.state.theme !== 'dark')}
    },
    windowWidth () {
      return this.$store.state.windowWidth
    },

    // NAVBAR STYLE
    classObj () {
      if      (this.verticalNavMenuWidth === 'default') return 'navbar-default'
      else if (this.verticalNavMenuWidth === 'reduced') return 'navbar-reduced'
      else if (this.verticalNavMenuWidth)               return 'navbar-full'
    },
    showPlanChip(){
      return false;
    },
    realShowPlanChip(){
      return false;
    },
    step1Visible () {
      return this.$store.state.windowWidth > this.calcOffset(this.step1);
    },
    step2Visible () {
      return this.$store.state.windowWidth > this.calcOffset(this.step2);
    },
    step3Visible () {
      return this.$store.state.windowWidth > this.calcOffset(this.step3);
    },
    step4Visible () {
      return this.$store.state.windowWidth > this.calcOffset(this.step4);
    },
    environmentalImpact(){
      return this.decimalsFormat (this.roleGeneralData.environmentalImpact, 2);
    },
    economicImpact(){
      return this.moneyFormat(this.roleGeneralData.economicImpact);
    },
    panelsImpact(){
      return this.decimalsFormat (this.roleGeneralData.panelsImpact, 1);
    },
    contractLevel(){
      return this.roleGeneralData.contract_level;
    },
  },
  methods: {
    calcOffset(base){
      return (this.showPlanChip) ? (base + this.offsetPlan) : base;
    },
    showSidebar () {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
    },
    showFeedback(){
      this.$store.commit('TOGGLE_IS_FEEDBACK_POPUP_ACTIVE', true)
    },
    execMethod(method){
      this[method]();
    },
    async goToImpactaMasPage(){
      await this.$router.push({name: 'impactaMasInversionista'});
    },
    async goToIncrementLevel(){
      await this.$router.replace({name: 'incrementInvestorContractLevel'}).catch(()=>{});
    },
    async goToPayFunds(){
      await this.$router.replace({name: 'abonarFondos'}).catch(()=>{});
    },
  }
}
</script>

