<template>
  <!-- CART DROPDOWN -->
  <vs-dropdown vs-custom-content vs-trigger-click	class="cursor-pointer">
      <vs-chip class="ml-1 mt-1 text-md bold" color="primary">Saldo {{balanceFormat}}
      </vs-chip>
      <vs-dropdown-menu class="credit-dropdown vx-navbar-dropdown dropdown-custom rightx" >
         <div class="text-center p-5 bg-primary text-white">
            <div class="flex items-center justify-center">
              <h3 class="text-white">{{ balanceFormat }}</h3>
              <vs-button class="ml-2" @click="switchBalanceOption" :icon="balanceIcon"></vs-button>
            </div>
            <p class="bold">
              Saldo disponible
            </p>
         </div>
         <component :is="scrollbarTag" ref="mainSidebarPs" class="scroll-area--cart-items-dropdowm p-0 mb-10" :settings="settings" :key="$vs.rtl">
         </component>
         <div
            class=" checkout-footer fixed bottom-0 rounded-b-lg text-dark font-semibold w-full p-2 text-center border border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light cursor-pointer"
            @click="goToCreditDetail()">
            <span class="flex items-center justify-center">
              <span class="ml-2">Abonar fondos</span>
            </span>
         </div>
      </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import formatHelper from "@mixins/formatHelper";
import {mapState} from "vuex";
import { mapMutations } from 'vuex';

export default {
  components: {
    VuePerfectScrollbar
  },
  mixins: [formatHelper],
  data () {
    return {
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  computed: {
    ...mapState("auth", ["roleGeneralData", "showInvestorBalance"]),
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    },
    balance(){
      return this.costFormat(this.roleGeneralData.balance)+" MXN";
    },
    balanceFormat(){
      return this.showInvestorBalance? this.balance:"$ * * * * * *";
    },
    balanceIcon(){
      return this.showInvestorBalance? "visibility":"visibility_off";
    }
  },
  methods: {
    ...mapMutations('auth', ['setShowInvestorBalance']),
    async goToCreditDetail(){
       await this.$router.push({name: 'abonarFondos'});
    },
    switchBalanceOption(){
      this.setShowInvestorBalance(!this.showInvestorBalance);
    }
  }
}

</script>

<style lang="scss" scoped>
.credit-dropdown {
  width: 250px;

  // Full width notification in mobile devices
  @media screen and (max-width: 500px) {
    width: 50vw;
    left: 65vw !important;

    .vs-dropdown--menu--after {
      display: none;
    }
  }

  .checkout-footer,
  .notification-footer {
    background-color: #f8f8f8;
  }
}

body.body-area-reduced {
  .credit-dropdown {
    left: 385px !important;
  }
}

body.body-area-default {
  .credit-dropdown {
    left: 565px !important;
  }
}
</style>