<template>
  <div class="the-navbar__user-meta flex items-center">

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ userName }}</p>
      <small>{{lastSession}}</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer v-step-15">

      <div class="con-img ml-3">
        <div v-if="hasName" class="rounded-full shadow-md cursor-pointer block user-initials">
          {{ getInitals }}
        </div>
        <img v-else :src="photoURL" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown" v-if="!loggedOut">
        <ul style="min-width: 10rem">

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/inversionista/perfil').catch(() => {})">
            <span>Mi perfil</span>
          </li>

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <a class="text-black hover:text-white" href="https://soporte.redgirasol.com/support/home" target="_blank">
              ¿Tienes dudas?
            </a>
          </li>

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="showFeedback">
            <span>¡Te escuchamos!</span>
          </li>

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="goToBlog">
            <span>Nuestro Blog</span>
          </li>

          <template v-if="showSwitchAccountButton">
            <vs-divider class="m-1" />
            <li
              class="flex py-2 pl-2 pr-3 cursor-pointer hover:bg-primary hover:text-white"
              @click="switchSessionRequest">
              <feather-icon icon="RepeatIcon" svgClasses="w-4 h-4" />
              <span class="ml-2">Cambiar sesión</span>
            </li>
          </template>

          <vs-divider class="m-1" />

          <li
            class="flex py-2 pl-2 pr-3 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Cerrar sesión</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import dateHelper from '@mixins/dateHelper';
import investorInfoHelper from "@/helpers/investorInfoHelper";
export default {
  mixins: [ dateHelper, investorInfoHelper],
  data () {
    return {
      loggedOut: false,
      female_avatar: "https://cdn.redgirasol.com/green/perfiles/mujer.jpg",
      male_avatar: "https://cdn.redgirasol.com/green/perfiles/hombre.jpg",
    }
  },
  computed: {
    ...mapState("auth", ["user"]),
    userName: function () {
      let name = null;
      if (this.user) {
        if (this.userIsMoral(this.UserPersonType)) {
          name = this.user.legal_representative_name;
        } else {
          name = this.user.full_name;
        }
      }
      return name;
    },
    lastSession : function () {
      let ss = "Último acceso: ";
      if(this.user != null && this.user.last_login != null && this.user.last_login != ''){
        ss += this.sessionFormat(this.user.last_login);
      }
      else {
        ss += "-";
      }
      return ss;
    },
    photoURL(){
      if(this.user.avatar_info){
        return this.user.avatar_info === 1 ? this.male_avatar : this.female_avatar;
      }
      else {
        return this.male_avatar;
      }
    },
    showSwitchAccountButton(){
      return this.HasMultipleRoles;
    },
    hasName() {
      return (this.user != null) && this.userName != null && this.userName != "";
    },
    getInitals() {
      if (this.hasName) {
        const splitedName = this.userName.split(" ");
        return `${splitedName[0][0]}${splitedName[splitedName.length-1][0]}`
      } else {
        return "--"
      }
    }
  },
  methods: {
    async logout () {
      this.showFullPageLoader();
      this.loggedOut = true;
      //await this.$router.replace({name: 'investorsLogout'});
      await this.timeout(600);
      await this.executeAppLogout();
    },
    async switchSessionRequest() {
      this.$store.commit("TOGGLE_IS_SWITCH_ACCOUNT_POPUP_ACTIVE", true);
    },
    async goToBlog(){
      window.open("https://blog.redgirasol.com", '_blank');
    },
    showFeedback(){
      this.$store.commit('TOGGLE_IS_FEEDBACK_POPUP_ACTIVE', true)
    },
    timeout(ms){
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  }
}
</script>
