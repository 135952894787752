<template>
  <!-- CART DROPDOWN -->
  <vs-dropdown vs-custom-content vs-trigger-click	class="cursor-pointer plan-dropdown-main">
    <vs-chip class="ml-3 mt-1 text-md bold" color="primary"> Monto Automatizado
      <feather-icon icon="EyeIcon" class="cursor-pointer ml-2" svg-classes="h-6 w-6"  />
    </vs-chip>
    <vs-dropdown-menu class="plan-dropdown vx-navbar-dropdown dropdown-custom rightx" >

      <div class="text-center p-5 bg-primary text-white">
        <h3 class="text-white">{{ availableMoney }} MXN</h3>
        <p class="opacity-80">Monto Automatizado</p>
      </div>

      <component :is="scrollbarTag" ref="mainSidebarPs" class="scroll-area--cart-items-dropdowm p-0 mb-10" :settings="settings" :key="$vs.rtl">
      </component>
      <div
        class=" checkout-footer fixed bottom-0 rounded-b-lg text-dark font-semibold w-full p-2 text-center border border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light cursor-pointer"
        @click="goToPlanPage()">
            <span class="flex items-center justify-center">
              <span class="ml-2">Mi forma de riego</span>
            </span>
      </div>

    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import formatHelper from "@mixins/formatHelper";
import {mapState} from "vuex";

export default {
  components: {
    VuePerfectScrollbar
  },
  mixins: [formatHelper],
  data () {
    return {
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
    }
  },
  computed: {
    ...mapState("auth", ["roleGeneralData"]),
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    },
    availableMoney(){
      return this.costFormat(0);
    }
  },
  methods: {
    async goToPlanPage(){
      await this.$router.push({name: 'planesInversion'});
    }
  }
}

</script>

<style lang="scss" scoped>
.plan-dropdown {
  width: 250px;

  // Full width notification in mobile devices
  @media screen and (max-width: 500px) {
    width: 50vw;
    left: 65vw !important;

    .vs-dropdown--menu--after {
      display: none;
    }
  }

  .checkout-footer,
  .notification-footer {
    background-color: #f8f8f8;
  }
}

body.body-area-reduced {
  .plan-dropdown {
    left: 485px !important;
  }
}

body.body-area-default {
  .plan-dropdown {
    left: 665px !important;
  }
}
</style>