<template>

  <vs-dropdown vs-custom-content vs-trigger-click	class="cursor-pointer" :color="colorx">
    <vs-chip style="color:#2d2d2d;" class="ml-3 mt-1 text-md bold" :color="colorx">
      <feather-icon icon="SunIcon" class="mr-2" svg-classes="h-4 w-4" />
      {{ this.formatVal(currentPreference) }} {{currentUnit}}
    </vs-chip>
    <feather-icon icon="ChevronDownIcon" class="mr-2 mt-1" svg-classes="h-6 w-6 text-green-light" />
    <vs-dropdown-menu class="vx-navbar-dropdown " >
      <vs-dropdown-item v-for="(pref, idx) in preferences" @click.native="updatePreference(pref)" :key="idx">
        <span>{{pref.label}} ({{pref.unit}})</span>
      </vs-dropdown-item>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import formatHelper from "@mixins/formatHelper";
import {mapState} from "vuex";
export default {
  name: "InvestorImpactsPrefs",
  mixins: [formatHelper],
  data(){
    return {
      colorx: "#CBFF00",
    }
  },
  computed: {
    ...mapState("auth", ["roleGeneralData"]),
    preferences(){
      return this.roleGeneralData.impacts;
    },
    currentPreference (){
      if(this.preferences){
        return this.preferences[this.roleGeneralData.impactsPref];
      }
      return null;
    },
    currentUnit(){
      if(this.currentPreference){
        let pref = this.currentPreference;
        if(pref.sunit !== undefined && pref.sunit !== null && pref.sunit !== 'none'){
          if(this.formatVal(pref) === 1 || this.formatVal(pref) === "1"){
            return pref.sunit;
          }
        }
        return pref.unit;
      }
      return null;
    },
  },
  methods: {
    formatVal(pref){
      if(pref){
        if(pref.format === "money"){
          return this.moneyFormat(pref.value);
        }
        else if(pref.format === "int" || pref.format === "float"){
          return this.roundedFormat(pref.value, 2);
        }
        else {
          return pref.value;
        }
      }
      return null;
    },
    async updatePreference(pref){
      this.roleGeneralData.impactsPref = pref.pref;
      try {
        await axios.post(`api/v2/investor/${this.InvestorId}/updateEnvironmentalPreferences`, {preference: pref.pref});
      }
      catch (e) {
      }
    }
  }
}
</script>

<style scoped>
.text-green-light {
  color: #CBFF00;
  stroke-width: 3;
}
</style>