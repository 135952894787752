/*=========================================================================================
  File Name: investorNavMenuItemsRestricted.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          dynamicTagCondition => method to define if the tag should be displayed
          dynamicTagLabel => method to display some text based on certain evaluations
          dynamicTagColor => method to apply on badge element based on certain evaluations
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: null,
    name: 'Mis inversiones',
    //tag: 'new',
    //tagColor: 'primary',
    icon: 'BriefcaseIcon',
    submenu: [
      {
        url: '/inversionista/portafolio',
        name: 'Mi portafolio',
        slug: 'miPortafolio',
        icon: 'BriefcaseIcon',
        step: 'v-step-3'
      },
      {
        url: '/inversionista/mis-proyectos',
        name: 'Mis proyectos',
        slug: 'misProyectosInversionista',
        icon: 'GridIcon',
        step: 'v-step-4'
      },
      {
        url: '/inversionista/mis-movimientos',
        name: 'Mis movimientos',
        slug: 'misMovimientosInversionista',
        icon: 'DatabaseIcon',
        step: 'v-step-4'
      },
      {
        url: '/inversionista/estados-cuenta',
        name: 'Estados de cuenta',
        slug: 'estadosCuentaInversionista',
        icon: 'FileTextIcon',
        step: 'v-step-4'
      },
    ]
  },
  {
    url: '/inversionista/oportunidades',
    name: 'Invertir',
    slug: 'oportunidades',
    icon: 'SunriseIcon',
    step: 'v-step-7',
    //tag: '4 semillas',
    //tagColor: 'primary',
  },
  {
    url: '/inversionista/abonar-fondos',
    name: 'Abonar fondos',
    slug: 'abonarFondos',
    icon: 'ChevronsUpIcon',
    step: 'v-step-6'
  },
  {
    url: '/inversionista/retirar-fondos',
    name: 'Retirar fondos',
    slug: 'retirarFondos',
    icon: 'ChevronsDownIcon',
    step: 'v-step-7'
  },
  {
    header: 'PROGRAMAS DE INVERSIÓN',
    icon: 'SettingsIcon',
    items: [
      {
        url: '/inversionista/plan-de-inversion',
        name: 'Planes de inversión',
        slug: 'planesInversion',
        icon: 'DropletIcon',
        step: 'v-step-11'
      },
      {
        url: '/inversionista/redferidos',
        name: 'Re(d)feridos',
        slug: 'redferidos',
        icon: 'Share2Icon',
        step: 'v-step-9'
      },
      // TODO-DELETE-IA: 31/08/2023
      // {
      //   url: '/inversionista/impacta-mas',
      //   name: 'ImpactaMás',
      //   slug: 'impactaMas',
      //   icon: 'AwardIcon',
      //   step: 'v-step-10'
      // },
    ]
  },
]
