<template>
  <div class="p-2 pt-1">
    <div>
      <div class="vx-row mt-0">
        <div class="vx-col w-full">
          <p>
            Por seguridad, tu sesión <span class="bold">no puede estar inactiva por más de 5 minutos</span>.
            Si deseas continuar navegando en el sitio, da clic en Continuar sesión. <br><br>
            De lo contrario, tu sesión será finalizada automáticamente en {{timer}} segundos.
          </p>
        </div>
      </div>
      <div class="vx-row mt-6">
        <div class="vx-col w-full">
          <div class="flex items-center flex-grow justify-left">
            <vs-button class="statement-btn" color="primary" @click="onContinue" >Continuar sesión</vs-button>
            <vs-button class="statement-btn ml-6" type="border" color="primary" @click="onCloseSession" >Finalizar sesión</vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IdleAlert",
  props: {
    timer: {
      type: Number,
      required: true
    }
  },
  methods: {
    async onContinue(){
      await this.$emit('on-continue-request');
    },
    async onCloseSession(){
      await this.$emit('on-close-request');
    }
  },
}
</script>

<style scoped>

</style>